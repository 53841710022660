@use '../../styles/typo' as typo;

.Products {
  @include typo.content_1;
  width: 100%;
}

.ThWidth {
  width: 40%;
}

.CursorPointer {
  cursor: pointer;
}

.LastCellWidth {
  width: 20%;
}

.Last {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.Action {
  cursor: pointer;
  @include typo.link_1;
}

.Search {
  margin: 0 !important;
  width: 100%;
}

.EmptyHeadline {
  font-size: 1.375rem;
  line-height: 1.45;
  margin-bottom: 0.5rem;
}

.EmptySubHeadline {
  color: #9A9FA8;
  font-size: 1rem;
  line-height: 1.5;
}

.CategoryTag {
  display: inline;
  margin-right: 8px;
}

.DownloadLink {
  color: var(--color-primary);

  &:hover,
  &:focus {
    color: #8caac6;
  }

  &:active {
    color: #537da3;
  }

  & .DownloadIcon {
    width: 20px;
  }

  & .EyeIcon {
    width: 20px;
    margin-right: 16px;
  }
}