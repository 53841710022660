@use '../../styles/typo' as typo;

@mixin page {
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  padding: 4rem 8.5rem;
  width: 66rem;
  box-sizing: border-box;
}

$border-color: #E6E8EA;
$background-light: #F5F7FB;
$primary-dark: #1C3D61;
$spacing-base: 1rem;

.Product {
  @include page;

  &Title,
  &Subtitle {
    @include typo.headline_0;
    margin: 0 0 2rem;
  }

  &Subtitle {
    margin: 4rem 0 2rem;
  }
  &ImageContainer {
    width: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}

.QuickFacts {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  margin-bottom: 2rem;

  &Item {
    border-radius: 4px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
  }

  &IconContainer {
    height: 131px;
    background-color: $primary-dark;
    border-radius: 4px 4px 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &Icon {
    max-width: 54px;
    max-height: 54px;
  }

  &HeadlineContianer {
    flex: 1;
    display: flex;
    align-items: center;
  }

  &Headline {
    @include typo.subtitle_1;
    font-weight: 700;
    margin: 0 24px;
    padding: 12px 0;
  }

  &NoteSup {
    font-size: 10px;
    line-height: normal;
  }

  &Notes {
    padding-inline-start: 12px;

    li {
      @include typo.content_3;
    }
  }
}

.DetailedInfos {
  &Content {
    @include typo.content_1;
    margin-bottom: 2rem;

    a {
      @include typo.link_1;
    }
  }
}


.Terminals {
  &Item {
    display: flex;
    align-items: start;

    > div {
      flex: 1;
    }

    &TerminalInfo,
    &AgencyInfo {
      margin-bottom: $spacing-base;
    }

    &Title {
      @include typo.content_2;
      margin-right: $spacing-base;

      &Uppercase {
        @include typo.content_2_uppercase;
      }
    }

    &Content {
      @include typo.content_1;
      display: flex;
      flex-direction: column;
    }

    &Link {
      @include typo.link_1;
      margin-top: 0.2rem;
    }
  }
}


.TimeTable {
  &Table :global(.fahrplan-table) {
    width: 100%;
    border-collapse: collapse;

    td {
      @include typo.content_1;
      padding: 6px;
      vertical-align: middle;
      border-bottom: #E6E8EA 1px solid;
    }

    tr:first-of-type {
      background-color: #F5F7FB;

      td {
        @include typo.content_2;
      }
    }
  }

  &Notice {
    @include typo.subtitle_1;
    margin: 4rem 0 2rem;
  }
}

.Contacts {
  &ItemWrapper {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 0.5rem;
  }

  &Item {
    &Summary {
      display: flex;
      align-items: center;
      gap: 32px;
      padding: 10px;

      &Image {
        align-self: flex-start;
        border-radius: 50%;
        height: 80px;
        width: 80px;
      }

      &Text,
      &Name{
        flex-grow: 1;
        @include typo.headline_5;
        letter-spacing: 0;
      }

      &Role {
        @include typo.content_1;
        letter-spacing: 0;
        margin-bottom: 8px;
      }

      &Detail {
        @include typo.content_1;
        letter-spacing: 0;
        padding: 0 24px 24px (80px + 10px + 32px);

        & > div,
        & > a {
          display: flex;
          align-items: center;
          gap: 0.5rem;
          margin-bottom: 0.25rem;

          &:last-child {
            margin-top: 1rem;
            margin-bottom: 0;
          }
        }

        & a {
          @include typo.link_1;
        }

        &Icon {
          height: 16px;
          width: 16px;
          color: var(--color-text-primary);
        }
      }
    }
  }
}